import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import ResourceGrid from 'components/molecules/ResourceGrid';
import CtaSection from 'components/organisms/CtaSection';
import CustomerStoriesHero from 'components/organisms/CustomerStoriesHero';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';

const CustomerStoriesContent = ({
  hero,
  newsletterCta,
  newsletterCta2,
  cta,
  resources = [],
}) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <CustomerStoriesHero
        title={hero.title}
        imageUrl={hero.image}
        description={hero.description}
        cta={hero.cta}
      />

      <ResourceGrid
        viewAllUrl="/customer-stories"
        cols={3}
        noIcon
        resources={resources.slice(0, 6)}
      />

      {resources.length > 6 && (
        <>
          <NewsletterCtaSection
            headline={newsletterCta?.headline}
            background="bg-yellow"
          />
          <ResourceGrid
            viewAllUrl="/customer-stories"
            cols={3}
            noIcon
            resources={resources.slice(6, showAll ? undefined : 6)}
          />
        </>
      )}

      {resources.length > 12 && !showAll && (
        <div className="pt-20 pb-36 text-center">
          <button
            className="bg-dark-grey text-white py-5 px-10 text-lg tracking-tighter leading-none hover:opacity-75 focus:opacity-75 transition rounded-full font-semibold"
            onClick={() => setShowAll(true)}
          >
            Load more
          </button>
        </div>
      )}

      <NewsletterCtaSection headline={newsletterCta2?.headline} />

      <CtaSection headline={cta?.headline} />
    </>
  );
};

const RESOURCES_FIXTURE = [
  {
    title: 'How a Midwest Retailer Grew Revenue with Skupos Amidst a Pandemic',
    thumbnailUrl: '/uploads/graphic_customer-stories-04.png',
    url: '/customer-stories',
    type: 'customer-story-retail',
    description:
      'This case study covers how Skupos Engage helped one business compete alongside national chains (and grow revenue) during a pandemic - all through offering the maximum discounts on key products.',
  },
];

export const CustomerStoriesPreview = ({ entry }) => {
  return (
    <CustomerStoriesContent
      {...entry.get('data').toJS()}
      resources={RESOURCES_FIXTURE}
    />
  );
};

const CustomerStoriesPage = ({
  data: {
    markdownRemark: { frontmatter },
    customerStories,
  },
}) => {
  const { seo } = frontmatter;

  const resources = customerStories.edges.map(({ node }) => {
    const { title, slug, featuredImage, resourceType, description } =
      node.frontmatter || {};

    return {
      title,
      thumbnailUrl: featuredImage,
      url: `/customer-stories/${slug}`,
      type: resourceType,
      description,
    };
  });

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <CustomerStoriesContent {...frontmatter} resources={resources} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query CustomerStoriesPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/customer-stories/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          image
          description
          cta {
            label
            url
          }
        }
        newsletterCta {
          headline
        }
        newsletterCta2 {
          headline
        }
        cta {
          headline
        }
      }
    }
    customerStories: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "customer-story" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featuredImage
            description
            resourceType
          }
        }
      }
    }
  }
`;
export default CustomerStoriesPage;
