import { Link } from 'gatsby';
import React from 'react';
import { html2react } from 'helpers/parser';

interface CustomerStoriesHeroProps {
  title: string;
  imageUrl: string;
  description: string;
  cta: { url: string; label: string };
}

const CustomerStoriesHero: React.FC<CustomerStoriesHeroProps> = ({
  title,
  imageUrl,
  description,
  cta,
}) => {
  return (
    <div>
      <div className="bg-yellow text-dark-grey pt-32 lg:pt-80 pb-48 lg:pb-110">
        <div className="u-container text-center">
          <h1 className="tracking-tightest font-semibold text-3xl md:text-6xl px-6 lg:px-md xl:px-32">
            {html2react(title)}
          </h1>
        </div>
      </div>
      <div className="u-container -mt-32 lg:-mt-52 px-6 lg:px-md xl:px-0">
        <img
          src={imageUrl}
          alt="Customer Stories"
          className="w-full h-auto mb-10 lg:mb-20"
        />
        <div className="md:px-24 lg:px-32 xl:px-52">
          <div className="mb-8 font-semibold text-2xl lg:text-4xl xl:text-4.5xl tracking-tightest leading-tight">
            {description}
          </div>
          <Link className="u-btn u-btn--outline" to={cta.url}>
            {cta.label}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CustomerStoriesHero;
